import React from "react"
import Page from "../../../components/page"
import ResponsiveImageGrid from "../../../components/responsive-image-grid"
import ScrollDown from "../../../components/scroll-down"
import { themes } from "../../../styles/themes"
import paths from "../../../utils/paths"
import useScreenSize from "../../../utils/useScreenSize"

const xRatedGrid = (labels, isSmallScreen) => [
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/x-rated/xxx-commode.jpg",
        title: isSmallScreen ? undefined : labels.xRatedXXXCommodeTitle,
        alt: labels.xRatedXXXCommodeAlt,
        imgTitle: labels.xxxCommodeImgTitle,
        titleContainerClass: "x-rated-xxx-commode-title-container"
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 32.33,
        src: "/images/x-rated/xxx-commode-handcuffs-detail.jpg",
        title: labels.xRatedXXXCommodeTitle,
        alt: labels.xRatedXXXCommodeDetailAlt,
        imgTitle: labels.xxxCommodeImgTitle
      },
      {
        type: "grid",
        weight: 32.33,
        orientation: "vertical",
        grid: [
          {
            type: "html",
            weight: isSmallScreen ? 48.5 : 40,
            html: (
              <div className="paragraph-div-centered-relatively x-rated-its-also-text-container">
                <p>{labels.xRatedItsAlsoText}</p>
              </div>
            )
          },
          {
            type: "image",
            weight: isSmallScreen ? 48.5 : 40,
            src: "/images/x-rated/x-armchair-black-white.jpg",
            title: labels.xRatedXArmchairTitle,
            alt: labels.xRatedXArmchairAlt,
            imgTitle: labels.xArmchairImgTitle
          }
        ]
      },
      {
        type: "image",
        weight: 32.33,
        src: "/images/x-rated/x-armchair-tabouret-black-red.jpg",
        alt: labels.xRatedXArmchairTabouretAlt,
        imgTitle: `${labels.xArmchairImgTitle}, ${labels.xxxTabouretCoffeeTableImgTitle}, ${labels.moonSideTableImgTitle}`
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/x-rated/xxx-club-x-armchair.jpg",
        title: labels.xRatedXXXClubTitle,
        alt: labels.xRatedXXXClubAlt,
        imgTitle: `${labels.xxxClubImgTitle}: ${labels.xArmchairImgTitle}, ${labels.moonSideTableImgTitle}, ${labels.coquetteOdetteImgTitle}`
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 35,
        src: "/images/x-rated/xxx-collection.jpg",
        title: labels.xRatedXXXCollectionTitle,
        alt: labels.xRatedXXXCollectionAlt,
        imgTitle: `${labels.xxxCollectionImgTitle}: ${labels.xArmchairImgTitle}, ${labels.xxxSofaImgTitle}, ${labels.xxxTabouretCoffeeTableImgTitle}`
      },
      {
        type: "image",
        weight: 63.5,
        src: "/images/x-rated/xxx-tabouret-coffee-table.jpg",
        title: labels.xRatedXXXTabouretCoffeeTableTitle,
        alt: labels.xRatedXXXTabouretCoffeeTableAlt,
        imgTitle: labels.xxxTabouretCoffeeTableImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 63.5,
        src: "/images/x-rated/xxx-sofa-black-red.jpg",
        title: labels.xRatedXXXSofaBRTitle,
        alt: labels.xRatedXXXSofaBlackRedAlt,
        imgTitle: labels.xxxSofaImgTitle
      },
      {
        type: "image",
        weight: 35,
        src: "/images/x-rated/xxx-sofa-black-red-back.jpg",
        alt: labels.xRatedXXXSofaBlackRedBackAlt,
        imgTitle: labels.xxxSofaImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/x-rated/xxx-sofa-gif.gif",
        title: labels.xRatedXXXSofaBWTitle,
        text: labels.xRatedNoLimitsText,
        textClass: "x-rated-no-limits-text",
        alt: labels.xRatedXXXSofaBlackWhiteAlt,
        imgTitle: labels.xxxSofaImgTitle
      }
    ]
  }
]

export default function XRated({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      title={labels.xRated}
      theme={themes(labels).ipsWhite(true)}
      description={labels.xRatedDescription}
      keywords={labels.xRatedKeywords}
      ogUrl={paths(labels).xRated}
      ogImage="/images/og-images/x-rated.jpg"
      footerPadding
    >
      <ScrollDown bigScreenOnly={true} />
      <ResponsiveImageGrid grid={xRatedGrid(labels, isSmallScreen)} />
    </Page>
  )
}
